import React from "react";
import {Link} from 'react-router-dom'
const MOBILEPLANS = () => {
  return (
    <>
      <div id="main-content" className="main-wrap page" role="main">
        <article
          className="main-content post-112708 page type-page status-publish has-post-thumbnail hentry"
          id="post-112708"
        >
          <div className="grid-x expanded collapse">
            <div className="cell small-12">
              <section
                className="hero hero--generic hero--has-components"
                style={{ background: "#1d1d49" }}
              >
                <div className="row align-center">
                  <div className="columns small-12 large-10">
                    <h1 className="entry-title"> Mobile Plans</h1>
                  </div>
                </div>
                <div className="row align-center">
                  <div className="columns small-12 large-12">
                    <div className="hero-components ">
                      <div className="row full-width-hero expanded collapse large-padding">
                        <div className="columns small-12 medium-12 large-6">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <ul>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Save up to $650 on the latest Samsung, Apple,
                                  and Google smartphones
                                </li>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Mobile data allowances up to 30 GB
                                </li>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Nationwide 5G phone coverage
                                </li>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Lower your bill by adding more phone lines
                                </li>
                              </ul>
                              <p
                                className="h4"
                                style={{ paddingTop: 25, textAlign: "left" }}
                              >
                                 mobile plans start at
                              </p>
                              <p style={{ textAlign: "left" }}>
                                <span
                                  className="h2"
                                  style={{ color: "#ffffff" }}
                                >
                                  $14.00<span className="h4">/mo.*</span>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="columns small-12 medium-24 large-3">
                              <div className="vc_btn3-container  text-left margin-bottom">
                                <Link 
                                  to="/contact"
                                  className="button button--is-big  button--generic width--standard"
                                  rel="nofollow noopener"
                                  
                                >
                                  View Plans
                                  <span className="show-for-sr">
                                    {" "}
                                    for 
                                  </span>
                                </Link> 
                              </div>
                            </div>
                            <div className="columns small-12 medium-24 large-9" />
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: 32 }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                        </div>
                        <div className="columns small-12 medium-12 large-6">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>
                                <img
                                  className="avatar alignnone wp-image-62562"
                                  src="../app/uploads/CTV_directresponse_ATT_wireless.jpg"
                                  alt="Smiling young woman holding a smartphone"
                                  width={600}
                                  height={400}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="row expanded collapse">
            <div className="column small-12">
              <section
                className="section--cta-sticky"
                id="cta-sticky-container"
              >
                <div className="row expanded collapsed align-center-middle">
                  <div className="columns small-12 medium-10 cta-sticky--column-padding-restrict">
                    <div className="cta-sticky" data-component="Call To Action">
                      <div className="row expanded collapsed align-center-middle">
                        <div className="columns small-12 show-for-small-only cta-sticky__button-area text-center no-padding-left-right">
                          <Link 
                            className="button button--generic button--is-big button--is-bold button--fills-container"
                            to="/contact"
                            
                            rel="nofollow"
                          >
                            Order Online
                          </Link> 
                        </div>
                        <div className="columns small-8 show-for-medium no-padding-left">
                          <div className="cta-sticky__heading">
                            <h2 className="text--is-light">
                              Get  Mobile now{" "}
                            </h2>
                          </div>
                        </div>
                        <div className="columns small-4 show-for-medium no-padding-right">
                          <Link 
                            className="button button--generic button--is-short-unlimited button--is-big button--is-bold float-right"
                            
                            rel="nofollow"
                            to="/contact"
                          >
                            Order Online
                          </Link> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
      <div className="row align-center large-padding">
        <div className="columns small-12 large-10 bg-white large-padding-full">
          <div className="post-wrap">
            <div className="row">
              <div className="columns small-12 medium-12 large-4">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2 style={{ fontSize: "1.5rem" }}>By The Gig</h2>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$14.00/mo.*</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li> Mobile’s most affordable plan</li>
                      <li>Add additional data for $14.00 per 1 GB</li>
                      <li>Pay only for the data you need</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link 
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                    
                  >
                    View Plan
                  </Link> 
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        *Price with two or more phone lines.
                        <br />
                        Data valid as of the publication date. Promotions and availability are subject to change and may differ depending on the location.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2 style={{ fontSize: "1.5rem" }}>Unlimited</h2>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$29.99/mo.*</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Includes 20 GB of full-speed data</li>
                      <li>5G coverage included</li>
                      <li>Great value for most customers</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link 
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                    
                  >
                    View Plan
                  </Link> 
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        *Price with two or more phone lines.
                        <br />
                        Data valid as of the publication date. Promotions and availability are subject to change and may differ depending on the location.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2 style={{ fontSize: "1.5rem" }}>Unlimited Plus</h2>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$39.99/mo.*</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Includes 30 GB of full-speed data</li>
                      <li>5G coverage included</li>
                      <li>Stream videos in HD</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link 
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                    
                  >
                    View Plan
                  </Link> 
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        *Price with two or more phone lines.
                        <br />
                        Data valid as of the publication date. Promotions and availability are subject to change and may differ depending on the location.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row vc_custom_1655844463214">
              <div className="columns small-12 medium-12 large-12">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    With a  Internet subscription, you may get the best  Mobile rates. To view  services available in your region, enter your zip code below.
                    </p>
                    <form
                      className="zip-finder zip-finder--inline"
                      data-form-location="Body"
                      data-abide=""
                      noValidate=""
                      data-zip-finder=""
                    >
                      <div className="zip-finder__wrapper">
                        <div className="zip-finder__fields">
                          <div className="zip-finder__input">
                            <input
                              type="tel"
                              name="zipcode"
                              required="required"
                              placeholder="Zip code"
                              autoComplete="off"
                              maxLength={5}
                              pattern="[0-9]{5}"
                            />
                            <span className="form-error">
                              Please enter a valid zip code.
                            </span>
                          </div>
                          <div className="zip-finder__button">
                            <button
                              className="button button--has-icon-on-right button--hide-spinner"
                              type="submit"
                            >
                              <h4 className="zip-finder__button__header">
                                Find
                              </h4>
                              <div className="button__icon">
                                <svg>
                                  <use xlinkto="#pin" />
                                </svg>
                              </div>
                              <div
                                className="spinner"
                                role="alert"
                                aria-live="assertive"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Plans"
                  id="Plans"
                  className="section_anchor "
                  data-anchor="Plans"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Save up to 60% on  Mobile</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table no-margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Plan{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Monthly price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Data allowance{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              By The Gig{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $14.00/line (per 1 GB){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              1 GB/line ($14.00/mo. per additional GB){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link 
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                                
                              >
                                View Plan
                              </Link> {" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Unlimited{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $29.99/line per mo.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              20 GB/line{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link 
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                                
                              >
                                View Plan
                              </Link> {" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Unlimited Plus{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $39.99/line per mo.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              30 GB/line{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link 
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                                
                              >
                                View Plan
                              </Link> {" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                      With a  Internet subscription, you may get the best  Mobile rates. To view  services available in your region, enter your zip code below.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    Weary of receiving large monthly cell phone bills? Compared to other providers like Verizon or AT&T,  Mobile offers savings of up to 60% on data plans. The options offered by  Mobile include countrywide phone service, 5G compatibility, unlimited calls and messages, and more. You will need a  Internet package in order to get  Mobile.
                    </p>
                    <p>
                    For even greater savings, we advise expanding your  Mobile package to include at least two lines. Standard prices from  Mobile are still quite competitive, but if you have friends or family who also want smartphone service, you may save money by taking advantage of the company's multi-line discounts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Bundles"
                  id="Bundles"
                  className="section_anchor "
                  data-anchor="Bundles"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Bundle  internet and mobile</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table no-margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label"></th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Download speed{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                               Mobile{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                               Advanced WiFi router{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet + Mobile{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $49.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 300 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              One free Unlimited line for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Included for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link 
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                                
                              >
                                View Plan
                              </Link> {" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet 500 Mbps + Mobile{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $69.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              One free Unlimited line for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Included for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link 
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                                
                              >
                                View Plan
                              </Link> {" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet 1000 Mbps + Mobile{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $89.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 1,000 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              One free Unlimited line for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Included for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link 
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                                
                              >
                                View Plan
                              </Link> {" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Data effective as of publish date. Offers and
                        availability may vary by location and are subject to
                        change.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    Would you be interested in purchasing  Mobile and  Internet together? You can get  services and hardware for a single, affordable fee when you purchase  One bundles.
                    </p>
                    <p>
                    One  Mobile Unlimited phone line, suitable for a free 12-month trial period, comes with every  One bundle. Additionally, 's Advanced Wi-Fi router, which provides improved internet security, is yours for free for a full year.
                    </p>
                    <p>
                    Choose from one of 's internet plans to complete your  One package. With internet speeds ranging from 300 to 1,000 Mbps, 's plans are suitable for every household, regardless of their internet requirements or budget.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>How to sign up</h3>
                  </div>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-4">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={150}
                            height={150}
                            src="../app/uploads/2023/01/family-icon-150x150.png"
                            className="vc_single_image-img attachment-thumbnail"
                            alt="Icon - outline of three people"
                            decoding="async"
                            title="family-icon"
                            srcSet="https://www.cabletv.com/app/uploads/2023/01/family-icon-150x150.png 150w, https://www.cabletv.com/app/uploads/2023/01/family-icon.png 360w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1675350220608">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          <strong>
                            Step 1: Check your family’s smartphones
                          </strong>
                          <br />
                          Bring your current smartphone and phone number to
                           Mobile.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-4">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={150}
                            height={150}
                            src="../app/uploads/mobile-icon-150x150.png"
                            className="vc_single_image-img attachment-thumbnail"
                            alt=""
                            decoding="async"
                            title="mobile-icon"
                            srcSet="https://www.cabletv.com/app/uploads/mobile-icon-150x150.png 150w, https://www.cabletv.com/app/uploads/mobile-icon.png 360w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1675350235851">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          <strong>Step 2: Get  Mobile</strong>
                          <br />
                          Choose any  phone plan to meet your family’s
                          budget and data needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-4">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={150}
                            height={150}
                            src="../app/uploads/router-icon-150x150.png"
                            className="vc_single_image-img attachment-thumbnail"
                            alt=""
                            decoding="async"
                            title="router-icon"
                            srcSet="https://www.cabletv.com/app/uploads/router-icon-150x150.png 150w, https://www.cabletv.com/app/uploads/router-icon.png 360w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1675350240767">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          <strong>Step 3: Get  Internet</strong>
                          <br />
                          Complete your  Mobile bundle with a 
                          Internet plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <section className="vc_cta3-container">
                  <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icons-on-border vc_cta3-icon-size-md vc_cta3-icons-in-box vc_cta3-icons-top">
                    <div className="vc_cta3-icons">
                      <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                        <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                          <span className="vc_icon_element-icon fa fa-wifi" />
                        </div>
                      </div>
                    </div>
                    <div className="vc_cta3_content-container">
                      <div className="vc_cta3-content">
                        <header className="vc_cta3-content-header" />
                        <h3>Interested in  Mobile service?</h3>
                        <p>
                          Check out our{" "}
                          <Link  to="">
                             Mobile review
                          </Link> {" "}
                          for a full breakdown of ’s cell phone plans.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Phones"
                  id="Phones"
                  className="section_anchor "
                  data-anchor="Phones"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> phones</h2>
                  </div>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-4">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={700}
                            height={700}
                            src="../app/uploads/2023/01/iphone-14_700x700.jpg"
                            className="vc_single_image-img attachment-medium"
                            alt="iPhone 14 smartphone"
                            decoding="async"
                            title="iphone-14_700x700"
                            srcSet="https://www.cabletv.com/app/uploads/2023/01/iphone-14_700x700.jpg 700w, https://www.cabletv.com/app/uploads/2023/01/iphone-14_700x700-150x150.jpg 150w"
                            sizes="(max-width: 700px) 100vw, 700px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-4">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={700}
                            height={700}
                            src="../app/uploads/2023/01/Pixel-7_700x700.jpg"
                            className="vc_single_image-img attachment-medium"
                            alt="Pixel 7 smartphone"
                            decoding="async"
                            title="Pixel-7_700x700"
                            srcSet="https://www.cabletv.com/app/uploads/2023/01/Pixel-7_700x700.jpg 700w, https://www.cabletv.com/app/uploads/2023/01/Pixel-7_700x700-150x150.jpg 150w"
                            sizes="(max-width: 700px) 100vw, 700px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-4">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={700}
                            height={698}
                            src="../app/uploads/2023/01/Samsung-Galaxy-Z-Flip3-5G_700x700.jpg"
                            className="vc_single_image-img attachment-medium"
                            alt="Samsung Galaxy Z Flip smartphone"
                            decoding="async"
                            title="Samsung-Galaxy-Z-Flip3-5G_700x700"
                            srcSet="https://www.cabletv.com/app/uploads/2023/01/Samsung-Galaxy-Z-Flip3-5G_700x700.jpg 700w, https://www.cabletv.com/app/uploads/2023/01/Samsung-Galaxy-Z-Flip3-5G_700x700-150x150.jpg 150w"
                            sizes="(max-width: 700px) 100vw, 700px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-12">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3>Bring your own phone</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                        Phones from the majority of the major carriers are supported by  Mobile. Use the Bring Your Own Device feature offered by  Mobile if you have an older phone from a smaller carrier and are concerned if it will function on  Mobile.
                        </p>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3>Buy a new phone</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                        Phones from the majority of the major carriers are supported by  Mobile. Use the Bring Your Own Device feature offered by  Mobile if you have an older phone from a smaller carrier and are concerned if it will function on  Mobile.
                        </p>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3>Trade-in offers</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                        Wish to upgrade from your current smartphone to a newer model?
                          With  Mobile's trade-in offers, you may exchange your old smartphone for a new one and save up to $650, depending on the smartphone you're buying or trading in.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Wireless"
                  id="Wireless"
                  className="section_anchor "
                  data-anchor="Wireless"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> Mobile features</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    For smartphone users,  Mobile provides a plethora of competitive features. What you need know about the main characteristics of  Mobile is as follows.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>Unlimited talk and text</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    You shouldn't be worrying about keeping an eye on your messages or minutes each month. Within the US, you may make as many calls and texts as you like with  Mobile.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>Mobile hotspot</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Need Wi-Fi access on the go but you’re nowhere near a{" "}
                      <Link  to=""> Out-of-Home hotspot</Link> ?
                      All  Mobile customers can use a smartphone as a
                      wireless hotspot to get tablets, laptops, or smart watches
                      online.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>5G coverage</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    With the fastest mobile data download and upload speeds available,  Mobile's clients may take advantage of the countrywide 5G coverage. If your smartphone is capable of supporting 5G, you'll be covered by every  Mobile plan that offers 5G coverage.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>International calling and data roaming</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    Maintaining contact with family members who live abroad is made easier with  Mobile. Limitless free minutes for calls to Mexico and Canada are included with every  Mobile plan, in addition to free international messages to over 200 countries.
                      Staying in touch with friends and family who live abroad is now more inexpensive thanks to  Mobile's low international call prices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="FAQ"
                  id="FAQ"
                  className="section_anchor "
                  data-anchor="FAQ"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> Mobile FAQ</h2>
                  </div>
                </div>
                <section className="section">
                  <div className="row align-left">
                    <div className="margin--bottom-15">
                      <div className="toggle-option__container">
                        <Link 
                          to=""
                          id="faq-opt0"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt0-minus faq-opt0-plus faq-opt0-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt0-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt0-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>What carrier does  Mobile use?</h3>
                            </div>
                          </div>
                        </Link> 
                        <div
                          id="faq-opt0-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                          Verizon's network serves as the basis for phone coverage provided by  Mobile. The same 5G and phone coverage that other Verizon customers receive will be available to you.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link 
                          to=""
                          id="faq-opt1"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt1-minus faq-opt1-plus faq-opt1-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt1-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt1-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>What are the three  Mobile plans?</h3>
                            </div>
                          </div>
                        </Link> 
                        <div
                          id="faq-opt1-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                             Mobile’s three plans are By The Gig
                            ($14.00/mo.), Unlimited ($29.99/mo.), and Unlimited
                            Plus ($39.99/mo.)
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link 
                          to=""
                          id="faq-opt2"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt2-minus faq-opt2-plus faq-opt2-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt2-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt2-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                Can you have  Mobile without internet?
                              </h3>
                            </div>
                          </div>
                        </Link> 
                        <div
                          id="faq-opt2-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            You cannot have  Mobile without 
                            Internet.  Mobile is only for 
                            customers.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link 
                          to=""
                          id="faq-opt3"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt3-minus faq-opt3-plus faq-opt3-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt3-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt3-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                What type of phone does  Mobile use?
                              </h3>
                            </div>
                          </div>
                        </Link> 
                        <div
                          id="faq-opt3-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                          Significant phone manufacturers including Apple, Samsung, Google, and Motorola are among those that  Mobile supports. To find out if your phone is compatible with  Mobile, use the IMEI checker provided by  Mobile.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link 
                          to=""
                          id="faq-opt4"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt4-minus faq-opt4-plus faq-opt4-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt4-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt4-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                Do you need a 5G phone to use  Mobile?
                              </h3>
                            </div>
                          </div>
                        </Link> 
                        <div
                          id="faq-opt4-text"
                          className="toggle-option__section"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            You do not need a 5G phone to get  Mobile.
                            Many older 4G phones will work on  Mobile.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default MOBILEPLANS;
