import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../Pages/contact.css"
const Navbar = () => {
  const [isshow, setisShow] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Add a scroll event listener to track scrolling
  window.addEventListener("scroll", () => {
    // When the page is scrolled down more than 100px, set isScrolled to true
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  });
  return (
    <header
    className={`ctv-header ${isScrolled ? "fixed" : ""}`} // Apply "fixed" class when isScrolled is true
    style={{ backgroundColor: "#1d253c" }}
  >
      <div className="row">
        <div className="columns">
          <Link to="/" className="ctv-logo">
            <img
              data-perfmatters-preload=""
              src="/Hyp Link pro-03.png"
              alt="hyplinkpro.com"
              style={{ height: "80px", width: "250px",paddingTop:"5px" }}
              loading="lazy"
            />
          </Link>
        </div>
        <div className="columns small-6 large-7 has-menu">
          <nav role="navigation" className="main-menu">
            <label htmlFor="toggle-menu">
              <div style={{backgroundColor:"white"}}
                className="toggle-mobile-nav"
                aria-controls="main-menu__container"
                title="Toggle Menu"
              >
                <span className="bars" />
              </div>
              <label>
                <input type="checkbox" name="toggle-menu" id="toggle-menu" />
                <div className="main-menu__container">
                  <form
                    className="searchform"
                    action="https://www.netwavetech.com/"
                    role="search"
                    data-search-form=""
                  >
                    <label htmlFor="s">
                      <span className="show-for-sr">Search</span>
                    </label>
                    <input
                      type="search"
                      aria-label="Search Text"
                      name="s"
                      placeholder="Enter Search Term or Zip Code"
                      defaultValue=""
                      maxLength={100}
                    />
                    <button type="submit">
                      <img
                        data-perfmatters-preload=""
                        src="app/themes/coolwhip-child/dist/images/pages/generic/search.svg"
                        alt="Submit Search"
                        loading="lazy"
                      />
                    </button>
                  </form>
                  <ul style={{backgroundColor:"#1d253c"}} className="main-menu__ul">
                    <li
                      id="menu-item-107339"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-107339"
                    >
                      <NavLink style={{color:"white"}} to="/">Home</NavLink>
                    </li>
                    <li
                      id="menu-item-107341"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-107341"
                    >
                      <NavLink style={{color:"white"}}
                        onClick={() => setisShow(!isshow)}
                        to="/shopinternet"
                      >
                        Services
                      </NavLink>
                      <ul
                        style={{ display: isshow && "block" }}
                        className={`vertical menu `}
                      >
                        <li
                          id="menu-item-103787"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-103787"
                        >
                          <Link to="/shopinternet">
                            Shop  Internet
                          </Link>
                        </li>
                        <li
                          id="menu-item-104164"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-104164"
                        >
                          <Link to="/shoptv">Shop  TV</Link>
                        </li>
                        <li
                          id="menu-item-105373"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-105373"
                        >
                          <Link to="/Deal"> Deals</Link>
                        </li>
                        <li
                          id="menu-item-107668"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-107668"
                        >
                          <Link to="/Bundles"> Bundle</Link>
                        </li>
                        <li
                          id="menu-item-94780"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-94780"
                        >
                          <Link to="/MOBILEPLANS">
                            Mobile Plans
                          </Link>
                        </li>
                        <li
                          id="menu-item-107139"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-107139"
                        >
                          <Link to="/channel">
                             Channel Lineup
                          </Link>
                        </li>
                        <li
                          id="menu-item-94783"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-94783"
                        >
                          <Link to="/voice"> Voice Plans</Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/internetreview">
                             Internet Review
                          </Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/Tvreview"> Tv review</Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/Cheapestinternet">
                            Cheapest  Internet
                          </Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/bundlesreview">
                             Bundles Review
                          </Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/mobilereview">
                             Mobile Review
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      id="menu-item-69722"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-69722"
                    >
                      <NavLink style={{color:"white"}} to="/contact">Contact</NavLink>
                    </li>
                  </ul>{" "}
                </div>
              </label>
            </label>
          </nav>
        </div>
        <div className="columns show-for-large large-3 header-search">
          <form
            className="searchform"
            action="https://www.netwavetech.com/"
            role="search"
            data-search-form=""
          >
            <label htmlFor="s">
              <span className="show-for-sr">Search</span>
            </label>
            <input
              type="search"
              aria-label="Search Text"
              name="s"
              placeholder="Enter Search Term or Zip Code"
              defaultValue=""
              maxLength={100}
            />
            <button type="submit">
              <img
                data-perfmatters-preload=""
                src="app/themes/coolwhip-child/dist/images/pages/generic/search.svg"
                alt="Submit Search"
                loading="lazy"
              />
            </button>
          </form>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
