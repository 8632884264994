import React from 'react'
import {Link} from 'react-router-dom'
import voiceimg from '../uploads/CTV_Best-Satellite-Featured.png'
const voice = () => {
  return (
    <>
    <div id="main-content" className="main-wrap page" role="main">
      <article
        className="main-content post-80557 page type-page status-publish has-post-thumbnail hentry"
        id="post-80557"
      >
        <div className="grid-x expanded collapse">
          <div className="cell small-12">
            <section
              className="hero hero--generic hero--has-components"
              style={{ background: "#1d1d49" }}
            >
              <div className="row align-center">
                <div className="columns small-12 large-10">
                  <h1 className="entry-title">
                     Voice: Plans, Prices, and More
                  </h1>
                  <p className="subtitle subtitle-header">
                  For landline phone service and to maintain contact with friends and family, use our  Voice recommendations.
                  </p>{" "}
                </div>
              </div>
              <div className="row align-center">
                <div className="columns small-12 large-12">
                  <div className="hero-components single-review-hero-card-container">
                    <div className="row full-width-hero expanded collapse">
                      <div className="columns small-12 medium-12 large-12">
                        <div className="single-review-hero-card">
                          <div className="single-review-hero-card__label">
                            Cheapest phone option
                          </div>{" "}
                          <div className="contents">
                            <div className="column-1">
                              <div className="logo-container">
                                <Link 
                                 to="/contact"
                                  data-brand=""
                                  rel="nofollow noopener"
                                  
                                >
                                  <img
                                    src={voiceimg}
                                    className="image--is-provider-logo"
                                    alt=""
                                    loading="lazy"
                                  />
                                </Link> {" "}
                                <div className="rating-container">
                                  <div>
                                    <style
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          "#rating_stars_65534ee79fdd6 .rating-star { width:18px; min-height:18px; background-size:18px; margin:0 2px; }#rating_stars_65534ee79fdd6 .remainder-star { width:16.2px; min-height:18px; background-size:18px; margin:0 2px; }#rating_stars_65534ee79fdd6 .stars-empty .rating-star-empty { min-height:18px !important; }@media screen and (max-width:768px){#rating_stars_65534ee79fdd6 .rating-star { width:18px; min-height:18px; background-size:18px; }#rating_stars_65534ee79fdd6 .remainder-star { width:16.2px; min-height:18px; background-size:18px; }#rating_stars_65534ee79fdd6 .stars-empty .rating-star-empty { min-height:18px !important; }}"
                                      }}
                                    />
                                    <div
                                      id="rating_stars_65534ee79fdd6"
                                      className="stars-container"
                                    >
                                      <div className="stars-empty">
                                        <div className="rating-star rating-star-empty" />
                                        <div className="rating-star rating-star-empty" />
                                        <div className="rating-star rating-star-empty" />
                                        <div className="rating-star rating-star-empty" />
                                        <div className="rating-star rating-star-empty" />
                                      </div>
                                      <div className="stars-full">
                                        <div className="rating-star rating-star-full" />
                                        <div className="rating-star rating-star-full" />
                                        <div className="rating-star rating-star-full" />
                                        <div className="rating-star rating-star-full remainder-star" />
                                      </div>
                                    </div>
                                    <div className="rating">
                                      <Link 
                                        to=""
                                        className="rating-link"
                                      >
                                        Editorial rating (3.9/5)
                                      </Link> 
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="column-2">
                              <div className="features-container">
                                <div className="features-html">
                                  <p />
                                  <p style={{ textAlign: "center" }}>
                                    <strong>Price:</strong> From $14.99/mo.*
                                    <br />
                                    <strong>Features: </strong>Free unlimited
                                    calls in North America
                                  </p>
                                  <p></p>
                                </div>{" "}
                              </div>
                            </div>
                            <div className="column-3">
                              <div className="cta-container">
                                <Link 
                                  className="button  button--generic button--is-auto-width button--is-big button--allows-wrap"
                                 to="/contact"
                                  data-element="Button"
                                  
                                  rel="nofollow noopener"
                                >
                                  View plans
                                </Link> 
                                <div className="cta-link">
                                  <Link  to="">Read full review</Link> 
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row full-width-hero expanded collapse">
                      <div className="columns small-12 medium-12 large-12">
                        <div className="vc_empty_space" style={{ height: 32 }}>
                          <span className="vc_empty_space_inner" />
                        </div>
                        <div className="wpb_text_column wpb_content_element ">
                          <div className="wpb_wrapper">
                            <p style={{ fontSize: 12 }}>
                              <em>
                                Data effective as of publish date. Offers and
                                availability may vary by location and are subject
                                to change.{" "}
                                <Link  to="">See full disclaimer.</Link> 
                              </em>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row expanded collapse">
          <div className="column small-12">
            <section className="section--cta-sticky" id="cta-sticky-container">
              <div className="row expanded collapsed align-center-middle">
                <div className="columns small-12 medium-10 cta-sticky--column-padding-restrict">
                  <div className="cta-sticky" data-component="Call To Action">
                    <div className="row expanded collapsed align-center-middle">
                      <div className="columns small-12 show-for-small-only cta-sticky__button-area text-center no-padding-left-right">
                        <Link 
                          className="button button--generic button--is-big button--is-bold button--fills-container"
                         to="/contact"
                          
                          rel="nofollow"
                        >
                          Order Online
                        </Link> 
                      </div>
                      <div className="columns small-8 show-for-medium no-padding-left">
                        <div className="cta-sticky__heading">
                          <h2 className="text--is-light">Get  now </h2>
                        </div>
                      </div>
                      <div className="columns small-4 show-for-medium no-padding-right">
                        <Link 
                          className="button button--generic button--is-short-unlimited button--is-big button--is-bold float-right"
                          
                          rel="nofollow"
                         to="/contact"
                        >
                          Order Online
                        </Link> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </article>
    </div>
    <div className="row align-center large-padding">
      <div className="columns small-12 large-10 bg-white large-padding-full">
        <div className="font-small color-gray post-info">
          <Link 
            to=""
            className="post-info__author-image"
          >
            <img
              alt=""
              data-src="https://secure.gravatar.com/avatar/2990ac19c0a7aa05e949e49b241428bc?s=40&d=mm&r=g"
              srcSet="https://secure.gravatar.com/avatar/2990ac19c0a7aa05e949e49b241428bc?s=80&d=mm&r=g 2x"
              className="avatar avatar-40 photo lazyload"
              height={40}
              width={40}
              decoding="async"
            />{" "}
          </Link> 
          <div className="post-info__author-info">
            <strong>
              By{" "}
              <Link 
                to=""
                title="Posts by Eric Chiu"
                rel="author"
              >
                Eric Chiu
              </Link> 
            </strong>
            <p className="post-info__share-block">
              Share{" "}
              <Link 
                to=""
                
                className="post-info__share-link facebook-link"
              >
                {/*?xml version="1.0" encoding="UTF-8"?*/}
                <svg
                  width="14px"
                  height="14px"
                  viewBox="0 0 14 14"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="All-Pages"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="CTV-SubtitleAuthorMove"
                      transform="translate(-390.000000, -514.000000)"
                    >
                      <g
                        id="Author-Copy-3"
                        transform="translate(299.000000, 489.000000)"
                      >
                        <g
                          id="Group-2"
                          transform="translate(52.000000, 0.000000)"
                        >
                          <g
                            id="Social-Comment-Date"
                            transform="translate(0.000000, 22.000000)"
                          >
                            <g id="Social">
                              <g
                                id="_Custom/SocialMedia/ic_facebook"
                                transform="translate(38.000000, 2.000000)"
                              >
                                <rect
                                  id="Bounds"
                                  x={0}
                                  y={0}
                                  width={16}
                                  height={16}
                                />
                                <path
                                  d="M14.6666667,8.04074227 C14.6666667,4.33633988 11.681901,1.33333333 8,1.33333333 C4.31809896,1.33333333 1.33333333,4.33633988 1.33333333,8.04074227 C1.33333333,11.388604 3.77123698,14.16348 6.95833333,14.6666667 L6.95833333,9.97960266 L5.265625,9.97960266 L5.265625,8.04074227 L6.95833333,8.04074227 L6.95833333,6.56301624 C6.95833333,4.88197187 7.95361979,3.95341495 9.47643229,3.95341495 C10.2058203,3.95341495 10.96875,4.08441903 10.96875,4.08441903 L10.96875,5.73507045 L10.128099,5.73507045 C9.2999349,5.73507045 9.04166667,6.25210425 9.04166667,6.78253978 L9.04166667,8.04074227 L10.890625,8.04074227 L10.5950521,9.97960266 L9.04166667,9.97960266 L9.04166667,14.6666667 C12.228763,14.16348 14.6666667,11.388604 14.6666667,8.04074227"
                                  id="Color-Fill"
                                  fill="#1C1C49"
                                  fillRule="evenodd"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </Link> {" "}
              <Link 
                to=""
                
                className="post-info__share-link twitter-link"
              >
                {/*?xml version="1.0" encoding="UTF-8"?*/}
                <svg
                  width="14px"
                  height="12px"
                  viewBox="0 0 14 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <path
                      d="M5.5264,13.5007835 C10.5578667,13.5007835 13.3098667,9.33225015 13.3098667,5.71731682 C13.3098667,5.59891682 13.3098667,5.48105015 13.3018667,5.36371682 C13.8372434,4.97646927 14.2993896,4.49698583 14.6666667,3.94771682 C14.1674022,4.16894443 13.6377756,4.31402543 13.0954667,4.37811682 C13.6665301,4.03624058 14.0939325,3.49852854 14.2981333,2.86505015 C13.7611507,3.18369298 13.1736779,3.40825656 12.5610667,3.52905015 C11.7131108,2.62739377 10.365717,2.4067102 9.27442628,2.99074611 C8.18313557,3.57478203 7.61934666,4.81829054 7.8992,6.02398348 C5.69967773,5.91371657 3.65038708,4.87482256 2.26133333,3.16585015 C1.53526547,4.415794 1.90612687,6.01484187 3.10826667,6.81758348 C2.67292864,6.80468084 2.24708233,6.68724384 1.86666667,6.47518348 C1.86666667,6.48638348 1.86666667,6.49811682 1.86666667,6.50985015 C1.867023,7.81203307 2.78493413,8.93360224 4.06133333,9.19145015 C3.65859712,9.30128484 3.23604298,9.31734044 2.82613333,9.23838348 C3.18450556,10.3527423 4.21150643,11.1161349 5.38186667,11.1381168 C4.41319202,11.8994139 3.21656507,12.3126916 1.98453333,12.3114502 C1.7668819,12.3110323 1.54944217,12.2978541 1.33333333,12.2719835 C2.58433917,13.0747976 4.03995381,13.5006325 5.5264,13.4986502"
                      id="path-1"
                    />
                  </defs>
                  <g
                    id="All-Pages"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="CTV-SubtitleAuthorMove"
                      transform="translate(-411.000000, -515.000000)"
                    >
                      <g
                        id="Author-Copy-3"
                        transform="translate(299.000000, 489.000000)"
                      >
                        <g
                          id="Group-2"
                          transform="translate(52.000000, 0.000000)"
                        >
                          <g
                            id="Social-Comment-Date"
                            transform="translate(0.000000, 22.000000)"
                          >
                            <g id="Social">
                              <g
                                id="_Custom/SocialMedia/ic_twitter"
                                transform="translate(59.000000, 2.000000)"
                              >
                                <rect
                                  id="Bounds"
                                  x={0}
                                  y={0}
                                  width={16}
                                  height={16}
                                />
                                <mask id="mask-2" fill="white">
                                  <use xlinkto="#path-1" />
                                </mask>
                                <use
                                  id="Color-Fill"
                                  fill="#1C1C49"
                                  fillRule="evenodd"
                                  xlinkto="#path-1"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </Link> {" "}
              <span className="post-info__divider">|</span> Aug 16, 2022
            </p>
          </div>
        </div>
        <div className="post-wrap">
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2>Is  Voice&nbsp;good?</h2>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    Between smartphones and video calling services, it’s
                    understandable if you forgot landline phone service was still
                    a thing.
                  </p>
                  <p>
                  The no-contract landline service offered by the supplier,  Voice, provides excellent coverage for international phone calls at a reasonable cost. However, obtaining the service may need you to go through more hoops than you would anticipate.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3> Voice&nbsp;pros and cons</h3>
                </div>
              </div>
              <div className="row ">
                <div className="columns small-12 medium-24 large-6">
                  <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_sep_color_grey vc_separator-has-text">
                    <span className="vc_sep_holder vc_sep_holder_l">
                      <span className="vc_sep_line" />
                    </span>
                    <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                      <div className="vc_icon_element-inner vc_icon_element-color-blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                        <span className="vc_icon_element-icon fa fa-thumbs-o-up" />
                      </div>
                    </div>
                    <h4>Pros</h4>
                    <span className="vc_sep_holder vc_sep_holder_r">
                      <span className="vc_sep_line" />
                    </span>
                  </div>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p>
                        <b>
                          <span style={{ color: "#2aaa52" }}>✓</span>
                        </b>{" "}
                        Free unlimited calling in North America
                        <br />
                        <b>
                          <span style={{ color: "#2aaa52" }}>✓</span>
                        </b>{" "}
                        Affordable international call rates
                      </p>
                    </div>
                  </div>
                </div>
                <div className="columns small-12 medium-24 large-6">
                  <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_border_width_3 vc_sep_pos_align_center vc_sep_color_grey vc_separator-has-text">
                    <span className="vc_sep_holder vc_sep_holder_l">
                      <span className="vc_sep_line" />
                    </span>
                    <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                      <div className="vc_icon_element-inner vc_icon_element-color-blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                        <span className="vc_icon_element-icon fa fa-thumbs-o-down" />
                      </div>
                    </div>
                    <h4>Cons</h4>
                    <span className="vc_sep_holder vc_sep_holder_r">
                      <span className="vc_sep_line" />
                    </span>
                  </div>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p>
                        <strong>
                          <span style={{ color: "#ff5733" }}>✗</span>
                        </strong>{" "}
                        Requires  TV<sup>&nbsp;</sup>or internet service
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                <Link 
                 to="/contact"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                  
                >
                  Sign up for 
                </Link> 
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    <strong>Jump to:</strong>
                  </p>
                  <ul>
                    <li>
                      <Link  to=""> Voice plans and bundles</Link> 
                    </li>
                    <li>
                      <Link  to=""> Voice features</Link> 
                    </li>
                    <li>
                      <Link  to=""> Voice equipment</Link> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div
                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey"
                id="plans"
              >
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2> Voice&nbsp;plans and bundles</h2>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    <Link  to=""></Link>  doesn’t offer 
                    Voice as a standalone package, so you’ll have to bundle with
                     Internet<sup>®</sup> or TV packages in order to get
                    your landline phone set up.
                  </p>
                  <p>
                    By combining  telephone service with an{" "}
                    <Link  to="">Internet or TV bundle</Link> , you won’t
                    have to worry about paying multiple bills to different
                    providers.
                  </p>
                  <p>
                  But if home phone service is your first need, then 's package restriction can be a pain. The lowest  Voice bundle ( Internet + Voice) still costs $64.98 per month for the first year, even though  Voice's $14.99 monthly bundled rate is among the lowest compared to other providers.
                  </p>
                  <p>
                  It's also challenging to get  Voice unless you also want TV or internet service, or if your existing provider contract is about to expire. This is because of 's bundle requirement.
                  </p>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3> home phone bundles in your area</h3>
                </div>
              </div>
              <div className="vc-table-scrolling scrolling-table">
                <div className="grid-x">
                  <div className="cell vc-table-scrolling__table-container">
                    <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                      <tbody>
                        <tr className="vc-table-scrolling__row scrolling-table__row">
                          <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                            Plans{" "}
                          </th>
                          <th className="vc-table-scrolling__cell scrolling-table__cell ">
                            Introductory prices{" "}
                          </th>
                          <th className="vc-table-scrolling__cell scrolling-table__cell ">
                            Download speed{" "}
                          </th>
                          <th className="vc-table-scrolling__cell scrolling-table__cell ">
                            Channel count{" "}
                          </th>
                          <th className="vc-table-scrolling__cell scrolling-table__cell ">
                            Phone plan{" "}
                          </th>
                          <th className="vc-table-scrolling__cell scrolling-table__cell ">
                            Details{" "}
                          </th>
                        </tr>
                        <tr className="vc-table-scrolling__row scrolling-table__row">
                          <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                            Internet + Voice{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            From $64.98/mo. for 12 mos.
                            <sup>
                              †<sup> </sup>
                            </sup>
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            Up to 300 Mbps (wireless speeds may vary){" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            N/A{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            Unlimited nationwide calling{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                            <Link 
                             to="/contact"
                              className="button button--generic button--is-auto-width button--is-big"
                              rel="nofollow noopener"
                              
                            >
                              View Plans
                              <span className="show-for-sr"> for </span>
                            </Link> {" "}
                          </td>
                        </tr>
                        <tr className="vc-table-scrolling__row scrolling-table__row">
                          <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                            TV Signature + Voice{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            From $74.98/mo. for 12 mos.
                            <sup>
                              †<sup> </sup>
                            </sup>
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            N/A{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            150+ channels{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            Unlimited nationwide calling{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                            <Link 
                             to="/contact"
                              className="button button--generic button--is-auto-width button--is-big"
                              rel="nofollow noopener"
                              
                            >
                              View Plans
                              <span className="show-for-sr"> for </span>
                            </Link> {" "}
                          </td>
                        </tr>
                        <tr className="vc-table-scrolling__row scrolling-table__row">
                          <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                            Internet + TV Signature + Voice{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            From $124.97/mo. for 12 mos.<sup>‡</sup>{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            Up to 300 Mbps (wireless speeds may vary){" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            150+ channels{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell ">
                            Unlimited nationwide calling{" "}
                          </td>
                          <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                            <Link 
                             to="/contact"
                              className="button button--generic button--is-auto-width button--is-big"
                              rel="nofollow noopener"
                              
                            >
                              View Plans
                              <span className="show-for-sr"> for </span>
                            </Link> {" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p style={{ fontSize: 12 }}>
                    <em>
                      Data effective as of publish date. Offers and availability
                      may vary by location and are subject to change.{" "}
                      <Link  to="">See full disclaimer.</Link> 
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div
                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey"
                id="features"
              >
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2> Voice&nbsp;features</h2>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                  For the vast part of North America, including the US, Mexico, Canada, and Puerto Rico, you may make unlimited phone calls with  Voice. Unlimited international calls to more than 70 countries, including Hong Kong, Spain, and the UK, are available for an additional $5 a month.
                  </p>
                  <p>
                  In addition to offering service both locally and internationally,  Voice also offers free online management tools, robocall prevention, and multiple incoming call screening.
                  </p>
                </div>
              </div>
              <div className="row ">
                <div className="columns small-12 medium-24 large-6">
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <ul>
                        <li>Voicemail</li>
                        <li>Readable voicemail</li>
                        <li>Forward all or selected calls</li>
                        <li>Set backup phone</li>
                        <li>VIP ring</li>
                        <li>3-way calling</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="columns small-12 medium-24 large-6">
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <ul>
                        <li>Repeat dialing</li>
                        <li>Caller ID on TV</li>
                        <li>Block unwanted callers</li>
                        <li>Forward calls when busy</li>
                        <li>Trace call</li>
                        <li>Call Guard</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                <Link 
                 to="/contact"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                  
                >
                  Sign up for 
                </Link> 
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div
                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey"
                id="equipment"
              >
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2> Voice&nbsp;equipment</h2>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                  A phone modem that links your house to 's phone network is included with your  Voice subscription. A combination modem that supports both internet and phone services from  may be provided to you, depending on your  package.
                  </p>
                  <p>
                    You won’t need to install any additional hardware, and the
                    modem is included with your  Voice fee.
                  </p>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                <Link 
                 to="/contact"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                  
                >
                  Sign up for 
                </Link> 
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div
                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey"
                id="final"
              >
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2>Final take</h2>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    Even if  Voice costs only $14.99 by itself when
                    bundled, ’s mandatory bundle requirements means you’ll
                    have to add TV or Internet service—the least you’ll pay is
                    $64.98 per month.
                  </p>
                  <p>
                  However, if you're already in the market for internet or TV service and need a cheap way to contact family or friends who live abroad,  Voice may be a good fit for your home due to its unlimited coverage throughout North America, reasonable international rates, and features like caller ID and readable voicemails.
                  </p>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                <Link 
                 to="/contact"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                  
                >
                  Sign up for 
                </Link> 
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div
                className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey"
                id="faq"
              >
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
              <div className="vc_empty_space" style={{ height: 32 }}>
                <span className="vc_empty_space_inner" />
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h2> Voice FAQ</h2>
                </div>
              </div>
              <section className="section">
                <div className="row align-left">
                  <div className="margin--bottom-15">
                    <div className="toggle-option__container">
                      <Link 
                        to=""
                        id="faq-opt0"
                        className="link--is-flex link--inherits-color"
                        data-toggle="faq-opt0-minus faq-opt0-plus faq-opt0-text"
                      >
                        <div className="toggle-option">
                          <div
                            id="faq-opt0-plus"
                            className="toggle-icon toggle--is-hidden"
                            data-toggler="toggle--is-shown"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-plus" />
                            </svg>
                          </div>
                          <div
                            id="faq-opt0-minus"
                            className="toggle-icon"
                            data-toggler="toggle--is-hidden"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-minus" />
                            </svg>
                          </div>
                          <div className="toggle-label">
                            <h3>What is included in  Voice?</h3>
                          </div>
                        </div>
                      </Link> 
                      <div
                        id="faq-opt0-text"
                        className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                        data-toggler="toggle--is-hidden"
                      >
                        <p>
                          Your  Voice service includes a bundled phone
                          modem and features like voicemail, robocall blocking,
                          and caller ID.
                        </p>
                      </div>
                    </div>
                    <div className="toggle-option__container">
                      <Link 
                        to=""
                        id="faq-opt1"
                        className="link--is-flex link--inherits-color"
                        data-toggle="faq-opt1-minus faq-opt1-plus faq-opt1-text"
                      >
                        <div className="toggle-option">
                          <div
                            id="faq-opt1-plus"
                            className="toggle-icon toggle--is-hidden"
                            data-toggler="toggle--is-shown"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-plus" />
                            </svg>
                          </div>
                          <div
                            id="faq-opt1-minus"
                            className="toggle-icon"
                            data-toggler="toggle--is-hidden"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-minus" />
                            </svg>
                          </div>
                          <div className="toggle-label">
                            <h3>Do I need  Voice?</h3>
                          </div>
                        </div>
                      </Link> 
                      <div
                        id="faq-opt1-text"
                        className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                        data-toggler="toggle--is-hidden"
                      >
                        <p>
                          You could benefit from  Voice if you’re looking
                          for an affordable way to call friends or family members
                          who live overseas.
                        </p>
                        <p>
                           Voice has unlimited calls across North America
                          for $14.99 per month when bundled, and you can add
                          unlimited calling to more than 70 countries for an extra
                          $5.00 per month.
                        </p>
                      </div>
                    </div>
                    <div className="toggle-option__container">
                      <Link 
                        to=""
                        id="faq-opt2"
                        className="link--is-flex link--inherits-color"
                        data-toggle="faq-opt2-minus faq-opt2-plus faq-opt2-text"
                      >
                        <div className="toggle-option">
                          <div
                            id="faq-opt2-plus"
                            className="toggle-icon toggle--is-hidden"
                            data-toggler="toggle--is-shown"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-plus" />
                            </svg>
                          </div>
                          <div
                            id="faq-opt2-minus"
                            className="toggle-icon"
                            data-toggler="toggle--is-hidden"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-minus" />
                            </svg>
                          </div>
                          <div className="toggle-label">
                            <h3>Can I cancel  Voice?</h3>
                          </div>
                        </div>
                      </Link> 
                      <div
                        id="faq-opt2-text"
                        className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                        data-toggler="toggle--is-hidden"
                      >
                        <p>
                           Voice has no contracts, so you can{" "}
                          <Link  to="">cancel the service</Link> {" "}
                          at any time.
                        </p>
                      </div>
                    </div>
                    <div className="toggle-option__container">
                      <Link 
                        to=""
                        id="faq-opt3"
                        className="link--is-flex link--inherits-color"
                        data-toggle="faq-opt3-minus faq-opt3-plus faq-opt3-text"
                      >
                        <div className="toggle-option">
                          <div
                            id="faq-opt3-plus"
                            className="toggle-icon toggle--is-hidden"
                            data-toggler="toggle--is-shown"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-plus" />
                            </svg>
                          </div>
                          <div
                            id="faq-opt3-minus"
                            className="toggle-icon"
                            data-toggler="toggle--is-hidden"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-minus" />
                            </svg>
                          </div>
                          <div className="toggle-label">
                            <h3>Does  Voice&nbsp;include a phone?</h3>
                          </div>
                        </div>
                      </Link> 
                      <div
                        id="faq-opt3-text"
                        className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                        data-toggler="toggle--is-hidden"
                      >
                        <p>
                        There is a bundled phone modem included with  Voice, but no home phone is included in the package. Your home phone jacks and 's network are connected via this device.
                        </p>
                      </div>
                    </div>
                    <div className="toggle-option__container">
                      <Link 
                        to=""
                        id="faq-opt4"
                        className="link--is-flex link--inherits-color"
                        data-toggle="faq-opt4-minus faq-opt4-plus faq-opt4-text"
                      >
                        <div className="toggle-option">
                          <div
                            id="faq-opt4-plus"
                            className="toggle-icon toggle--is-hidden"
                            data-toggler="toggle--is-shown"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-plus" />
                            </svg>
                          </div>
                          <div
                            id="faq-opt4-minus"
                            className="toggle-icon"
                            data-toggler="toggle--is-hidden"
                          >
                            <svg className="image--icon-size-32 color--generic-color1">
                              <use xlinkto="#icon-minus" />
                            </svg>
                          </div>
                          <div className="toggle-label">
                            <h3>Does  charge extra for voicemail?</h3>
                          </div>
                        </div>
                      </Link> 
                      <div
                        id="faq-opt4-text"
                        className="toggle-option__section"
                        data-toggler="toggle--is-hidden"
                      >
                        <p>
                           doesn’t charge extra for voicemail—the feature
                          is included for free with  Voice.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                <span className="vc_sep_holder vc_sep_holder_l">
                  <span className="vc_sep_line" />
                </span>
                <span className="vc_sep_holder vc_sep_holder_r">
                  <span className="vc_sep_line" />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <div
                className="wpb_text_column wpb_content_element "
                id="disclaimer"
              >
                <div className="wpb_wrapper">
                  <p>
                    <strong>Disclaimer</strong>
                  </p>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p />
                  <p style={{ fontSize: 12 }}>
                    <em>
                    * Voice: Limited-time offer; subject to change; only available to qualifying residential customers who haven't subscribed to any services in the last 30 days and who don't owe Charter any money. Terms in general: HOME PHONE:  Voice is now offering a special pricing of $14.99 per month; usual rates will resume after the first year. Unlimited calling is available for calls to and from the United States, Puerto Rico, Guam, Canada, Mexico, the Virgin Islands, and other locations. All relevant service terms and conditions apply to the services, and they are subject to change. Certain regions may not have access to all services. There are restrictions. Kindly enter your address to find out the availability.
                    </em>
                  </p>
                  <p style={{ fontSize: 12 }}>
                    <em>
                    This is a limited-time deal for Internet + Voice that is only available to qualifying home customers who haven't subscribed to any services in the last 30 days and have no outstanding obligations to Charter. It is subject to change. The promotional price for  Internet® is $49.99 per month; usual prices are apply hyplink pro after the first year.  Voice®: When packaged, the cost is $14.99 per month. Additional taxes, fees, and surcharges apply, and they may vary before and after the promotional time. Additional services, equipment, and installation/network activation costs are also extra. Terms in general: Internet speed is dependent on a wired connection and may change depending on the address. The wireless speeds are subject to change. HOME PHONE: Calls inside the United States, Canada, Mexico, Puerto Rico, Guam, the Virgin Islands, and other countries are all included in the unlimited calling plan. All relevant service terms and conditions apply to the services, and they are subject to change. Provided Services
                    </em>
                  </p>
                  <p style={{ fontSize: 12 }}>
                    <em>
                    Offer: TV + Voice; limited time only; subject to change; only available to eligible residential customers with

not signed up for any services in the 30 days before

Those who are not in default under the Charter. Broadcast

$49.99/month is the TV®  offer price; regular fees apply.

Afterwards, apply. Broadcast Voice®: It costs $14.99 a month when

combined. Levies, levies, and other costs (broadcast surcharge up

$21.00/month) more, and subject to vary both during and after

the duration of the campaign; network activation and installation,

Extras include additional services and equipment. Terms in general:

TV: Requires TV equipment; fees may apply. HD and Channel

programmatic accessibility according to service level. Reconcile

Some TV programs may require credentials in order to stream.

internet. HOUSEPHONE: Unrestricted calling is available for calls within

the US, Mexico, Canada, and Puerto
                    </em>
                  </p>
                  <p style={{ fontSize: 12 }}>
                    <em>
                    TV + Internet Voice offer: Time-limited; subject to change; available only to eligible home customers

who did not sign up for any services in the preceding

30 days and who do not owe Charter any further money.

The offer price for  TV®  is $49.99 per month; regular

After year 1, rates take effect. Price of the  Internet® promotion

is $49.99/month; after year 1, regular prices are apply hyplink pro. Broadcast

Voice®: When packaged, the cost is $14.99/month. Fees, taxes, and

surcharges (up to $21.00/month for broadcast fees) farther and

liable to alter both during and beyond the promotional period;

equipment, setup/network activation, and other

Services cost more. Terms in general: Internet speed: determined by

wired link and might differ depending on the address. wireless velocities

may differ. TV: Requires TV equipment; fees may apply.

Availability of channels and HD programming
                      <br />
                    </em>
                  </p>
                  <p style={{ fontSize: 12 }}>
                    <em>
                    ◊A client must order and install a qualified Triple Play or restricted Double Play promotion in order to be eligible for the Contract Buyout Program; offers aren't available everywhere. The offer is exclusively accessible to eligible customers who do not have any outstanding debts to Charter. The Early Termination Fee listed on the final bill from the prior provider will determine the payment amount, which cannot be more than $500. To view the requirements for the Contract Buyout, visit .com/buyout.
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default voice