import './App.css';
import Navbar from './Main/Navbar';
import Home from './Pages/Home';
import Footer from './Main/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ShopInternet from './Pages/ShopSpectrumInternet';
import ShopTv from './Pages/ShopspectrumTv';
import Deal from './Pages/SpectrumDeal';
import Bundles from './Pages/SpectrumBundles';
import MOBILEPLANS from './Pages/SPECTRUMMOBILEPLANS'
import Cahnnel from './Pages/Spectrumcahnnel';
import Voice from './Pages/Spectrumvoice';
import InternetRevew from './Pages/SpectrumInternetRevew';
import Tvreview from './Pages/SpectrumTvreview';
import Cheapestinternet from './Pages/Cheapestinternet';
import Bundlesreview from './Pages/Spectrumbundlesreview';
import Mobilereview from './Pages/Spectrummobilereview';
import Contact from './Pages/Contact';
function App() {
  return (
    <>
    <BrowserRouter>
    <Navbar/>
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/shopinternet' element={<ShopInternet/>}/>
      <Route path='/shoptv' element={<ShopTv/>}/>
      <Route path='/Deal' element={<Deal/>}/>
      <Route path='/Bundles' element={<Bundles/>}/>
      <Route path='/MOBILEPLANS' element={<MOBILEPLANS/>}/>
      <Route path='/Channel' element={<Cahnnel/>}/>
      <Route path='/Voice' element={<Voice/>}/>
      <Route path='/internetreview' element={<InternetRevew/>}/>
      <Route path='/Tvreview' element={<Tvreview/>}/>
      <Route path='/Cheapestinternet' element={<Cheapestinternet/>}/>
      <Route path='/bundlesreview' element={<Bundlesreview/>}/>
      <Route path='/mobilereview' element={<Mobilereview/>}/>
      <Route path='/contact' element={<Contact/>}/>
     </Routes>
     <Footer/>
    </BrowserRouter>
      </>
  );
}

export default App;
